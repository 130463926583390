import mutations from './mutations';
import actions from './actions';
import getters from './getters';



export default {
    namespaced:true,
    state(){
        return {
            loadAuthState:false,
            authToken: {
                token:null,
                expiry:null
            },
            authAuthentication : {
                isAuthenticated : false,
                isAccountCreated : false,
                isAccountVerified : false,
                isProfileCreated : false
            },
            authCredentials : {
                accountHostName: null,
                accountName: null,
                accountEmail: null,
                accountPicture: "default.png",
                accountHostPicture: "default_logo.png",
                accountType :  "organization",
                accountId : null,
                accountHostId : null
            },
            authPermissions: []
        }
    },
    mutations:mutations,
    actions:actions,
    getters:getters
};
