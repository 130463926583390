export default {
    setLoadAuthState(state, payload){
        state.loadAuthState = payload;
    },
    setAuthToken(state, payload){
        state.authToken = payload;
    },
    setAuthAuthentication(state, payload){
        state.authAuthentication = {...state.authAuthentication, ...payload};
    },
    setAuthCredentials(state, payload){
        state.authCredentials = {...state.authCredentials, ...payload};
    },
    setAuthPermissions(state, payload){
        state.authPermissions = payload;
    }
};
