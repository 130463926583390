import mutations from './mutations';
import actions from './actions';
import getters from './getters';



export default {
    namespaced:true,
    state(){
        return {
            applicantList: false,
            recruitmentPhase: 'longlist',
            applicantFilters: {
                activeFilter: false,
                questions: false,
                gender: false,
                country: false,
                qualification: false
            }
        }
    },
    mutations:mutations,
    actions:actions,
    getters:getters
};
