<template>
    <div>
        <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-jd-options"
         href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <slot name="title">

          </slot>
        </a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-jd-options">
            <slot>

            </slot>
        </div>
    </div>
</template>

<script>
export default{
//     props: {
//         showFooter:{
//             type: String,
//             required: false
//         }
//     },
}
</script>
