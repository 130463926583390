<template>
    <nav-top></nav-top>
    <nav-sidebar></nav-sidebar>
</template>

<script>
import NavTop from '@/components/nav/NavTop.vue';
import NavSidebar from '@/components/nav/NavSidebar.vue';

export default{
    components: {
        NavTop,
        NavSidebar
    }
}
</script>
