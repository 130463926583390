export default {
    executeSetApplicantList(context, payload){
        context.commit("setApplicantList", payload);
    },
    executeSetRecruitmentPhase(context, payload){
        context.commit("setRecruitmentPhase", payload);
    },
    executeSetApplicantFilters(context, payload){
        context.commit("setApplicantFilters", payload);
    }
};
