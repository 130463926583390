
const coreLocalUrl = {
    jobsUrl:"http://127.0.0.1:8083/",
    tmsUrl:"http://127.0.0.1:8080/",
    backendUrl:"http://127.0.0.1:5000/",
    backendStaticUrl:"http://127.0.0.1:5000/"
}

const coreGLocalUrl = {
    jobsUrl:"http://127.0.0.1:8083/",
    tmsUrl:"http://127.0.0.1:8080/",
    backendUrl:"http://127.0.0.1:8000/",
    backendStaticUrl:"http://127.0.0.1:8000/"
}

const coreRemoteUrl = {
    jobsUrl:"https://www.succour.net/",
    tmsUrl:"https://tms.succour.net/",
    backendUrl:"https://api.succour.net/",
    backendStaticUrl:"https://api.succour.net/"
}

const coreFRemoteUrl = {
    jobsUrl:"http://www.succour.net/",
    tmsUrl:"http://tms.succour.net/",
    backendUrl:"http://api.succour.net/",
    backendStaticUrl:"http://api.succour.net/"
}

const urlOptions= {
    coreLocalUrl,
    coreGLocalUrl,
    coreRemoteUrl,
    coreFRemoteUrl
}


const coreUrl = urlOptions.coreRemoteUrl;


export default { coreUrl }
