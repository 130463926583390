import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store/index';
import axios from 'axios';
import urls from '@/site_config/index';

const coreUrl = urls.coreUrl;

const routes = [
  { path: '/', redirect:'/login'},
  {
    path: '/logout',
    name: 'AuthLogout',
    component: () => import('../pages/auth/AuthLogout.vue')
  },
  {
    path: '/login',
    name: 'AuthLogin',
    component: () => import('../pages/auth/AuthLogin.vue'),
    meta: { requiresUnauth: true}
  },
  {
    path: '/sign_up',
    name: 'AuthSignUp',
    component: () => import('../pages/auth/AuthSignUp.vue'),
    meta: { requiresUnauth: true}
  },
  {
    path: '/forgot_password',
    name: 'AuthForgotPassword',
    component: () => import('../pages/auth/AuthForgotPassword.vue')
  },
  {
    path: "/confirm_account/:confirmId",
    name: "AuthConfirmAccount",
    props: true,
    component: () => import("../pages/auth/AuthConfirmAccount.vue")
  },
  {
    path: '/reset_password/:resetId',
    name: 'AuthResetPassword',
    props: true,
    component: () => import('../pages/auth/AuthResetPassword.vue')
  },
  {
    path: '/alert_notifications',
    name: 'AlertNotifications',
    component: () => import('../pages/alert/AlertNotifications.vue')
  },
  {
    path: '/alert_calendar',
    name: 'AlertCalendar',
    component: () => import('../pages/alert/AlertCalendar.vue')
  },
  {
    path: '/alert_task',
    name: 'AlertTask',
    component: () => import('../pages/alert/AlertTask.vue')
  },
  {
    path: '/alert_message',
    name: 'AlertMessage',
    component: () => import('../pages/alert/AlertMessage.vue')
  },
  {
    path: '/dashboard',
    name: 'ReportsDashboard',
    component: () => import('../pages/reports/ReportsDashboard.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/reports_analytics',
    name: 'ReportsAnalytics',
    component: () => import('../pages/reports/ReportsAnalytics.vue')
  },
  {
    path: '/reports_activity_log',
    name: 'ReportsActivityLog',
    component: () => import('../pages/reports/ReportsActivityLog.vue')
  },
  {
    path: '/recruitment_create_jd',
    name: 'RecruitmentCreateJD',
    component: () => import('../pages/recruitment/RecruitmentCreateJD.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_jd_list',
    name: 'RecruitmentJDList',
    component: () => import('../pages/recruitment/RecruitmentJDList.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_update_job_ad/:jobId',
    name: 'RecruitmentUpdateJobAd',
    props: true,
    component: () => import('../pages/recruitment/RecruitmentUpdateJobAd.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_post_job_ad',
    name: 'RecruitmentPostJobAd',
    component: () => import('../pages/recruitment/RecruitmentPostJobAd.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_job_ad_list',
    name: 'RecruitmentJobAdList',
    component: () => import('../pages/recruitment/RecruitmentJobAdList.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_manage_applicants/:jobLinkTitle/:jobLinkId',
    name: 'RecruitmentManageApplicants',
    component: () => import('../pages/recruitment/RecruitmentManageApplicants.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_talent_pool',
    name: 'RecruitmentTalentPool',
    component: () => import('../pages/recruitment/RecruitmentTalentPool.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/recruitment_accessment_config',
    name: 'RecruitmentAccessmentConfig',
    component: () => import('../pages/recruitment/RecruitmentAccessmentConfig.vue')
  },
  {
    path: '/recruitment_manage_templates',
    name: 'RecruitmentManageTemplates',
    component: () => import('../pages/recruitment/RecruitmentManageTemplates.vue')
  },
  {
    path: '/recruitment_third_party',
    name: 'Recruitment3rdParty',
    component: () => import('../pages/recruitment/RecruitmentThirdParty.vue')
  },
  {
    path: '/test_questions',
    name: 'TestQuestions',
    component: () => import('../pages/test/TestQuestions.vue')
  },
  {
    path: '/test_categories',
    name: 'TestCategories',
    component: () => import('../pages/test/TestCategories.vue')
  },
  {
    path: '/test_tests',
    name: 'TestTests',
    component: () => import('../pages/test/TestTests.vue')
  },
  {
    path: '/test_list',
    name: 'TestList',
    component: () => import('../pages/test/TestList.vue')
  },
  {
    path: '/account_update_profile',
    name: 'AccountUpdateProfile',
    component: () => import('../pages/account/AccountUpdateProfile.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/account_view_profile',
    name: 'AccountViewProfile',
    component: () => import('../pages/account/AccountViewProfile.vue')
  },
  {
    path: '/personal_account_profile',
    name: 'PersonalProfile',
    component: () => import('../pages/personal/PersonalAccountProfile.vue')
  },
  {
    path: '/personal_account_activity_log',
    name: 'PersonalAccountActivityLog',
    component: () => import('../pages/personal/PersonalAccountActivityLog.vue')
  },
  {
    path: '/personal_account_preferences',
    name: 'PersonalAccountPreferences',
    component: () => import('../pages/personal/PersonalAccountPreferences.vue')
  },
  {
    path: '/admin_manage_team',
    name: 'AdminManageTeam',
    component: () => import('../pages/admin/AdminManageTeam.vue')
  },
  {
    path: '/admin_manage_roles_permissions',
    name: 'AdminManageRolesPermissions',
    component: () => import('../pages/admin/AdminManageRolesPermissions.vue')
  },
  {
    path: '/support_faqs',
    name: 'SupportFaqs',
    component: () => import('../pages/support/SupportFaqs.vue')
  },
  {
    path: '/support_tutorials',
    name: 'SupportTutorials',
    component: () => import('../pages/support/SupportTutorials.vue')
  },
  {
    path: '/support_contact_us',
    name: 'SupportContactUs',
    component: () => import('../pages/support/SupportContactUs.vue')
  },
  {
    path: '/support_features_request',
    name: 'SupportFeaturesRequest',
    component: () => import('../pages/support/SupportFeaturesRequest.vue')
  },
  {
    path: '/settings_account_credentials',
    name: 'SettingsAccountCredentials',
    component: () => import('../pages/settings/SettingsAccountCredentials.vue')
  },
  {
    path: '/settings_account_preferences',
    name: 'SettingsAccountPreferences',
    component: () => import('../pages/settings/SettingsAccountPreferences.vue')
  },
  {
    path: '/settings_account_subscription',
    name: 'SettingsAccountSubscriptions',
    component: () => import('../pages/settings/SettingsAccountSubscriptions.vue')
  },
  {
      path: '/:not_found(.*)',
      name: 'NotFound',
      component: () => import('../pages/defaults/NotFound.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeResolve(async (to, _, next) => {

    if(store.getters['auth/getLoadAuthState']){
        let canAccess = store.getters['auth/getAuthAuthentication'].isAuthenticated
        if (to.meta.requiresAuth && !canAccess ){
            next('/login');
        } else if (to.meta.requiresUnauth && canAccess ) {
            window.location.replace(coreUrl.tmsUrl+'dashboard');
        } else {
            next();
        }
    }else{
        let canAccess = false;
        try{
            canAccess = await getGlobalAuthState();
        }catch(e){
            canAccess = e;
        }


         if (to.meta.requiresAuth && !canAccess ){
             next('/login');
         } else if (to.meta.requiresUnauth && canAccess ) {
             window.location.replace(coreUrl.tmsUrl+'dashboard');
         } else {
             next();
         }
    }



})



// get the current auth live state
function getGlobalAuthState(){

    return new Promise((resolve,reject)=>{

        let responseData = false;

        axios.get(coreUrl.backendUrl+'get_org_session_data',{withCredentials: true}).then((response) =>{
            // return the full token to the token variable
            // If successful, get the resppnse data
            responseData = response.data.data;

            let payload = {
                data:responseData,
                state:true
            }

            store.dispatch("auth/executeSetLoadAuthState", payload);
            resolve(response.data.status)
            // resolve(responseData.authentication.isAuthenticated)

        }).catch((error)=>{
               // if an error occours, do this
              reject(error.response.data.status)
        })



    });

}


export default router
