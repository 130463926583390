import { createStore } from 'vuex';

import globals from './modules/globals/index.js';
import auth from './modules/auth/index.js';
import recruitment from './modules/recruitment/index.js';
import applicant from './modules/applicant/index.js';
import account from './modules/account/index.js';

const store =  createStore({
  modules: {
      globals:globals,
      auth:auth,
      recruitment:recruitment,
      applicant:applicant,
      account:account
  }
});

export default store;
