<template>
    <!-- BEGIN: Header-->
    <nav class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl" >
        <div class="navbar-container d-flex content">
            <div class="bookmark-wrapper d-flex align-items-center">
                <ul class="nav navbar-nav d-xl-none">
                    <li class="nav-item">
                        <a class="nav-link menu-toggle" href="#">
                            <i class="bi bi-list bs-icon-small" ></i>
                        </a>
                    </li>
                </ul>
                <ul class="nav navbar-nav bookmark-icons">
                    <li class="nav-item d-none d-lg-block">
                        <router-link to="/recruitment_post_job_ad" class="nav-link  ps-0">
                         <i class="bi bi-megaphone bs-icon-small" ></i>
                         Post new job
                        </router-link>
                    </li>
                </ul>
                <ul class="nav navbar-nav">
                    <li class="nav-item d-none d-lg-block">
                        <a class="nav-link bookmark-star " @click="toggleBookmarkStatus"  href="#">
                            <i class="bi bi-star bs-icon-small"  ></i>
                            Bookmarks
                        </a>
                        <div class="bookmark-input search-input" :class="bookmarkStatus">
                            <div class="bookmark-input-icon">
                                <i class="bi bi-search bs-icon-small" ></i>
                            </div>
                            <input class="form-control input" type="text" placeholder="Bookmark"
                                tabindex="0" data-search="search">
                            <ul class="search-list search-list-bookmark ps show"></ul>
                        </div>
                    </li>
                </ul>
            </div>
            <ul class="nav navbar-nav align-items-center ms-auto">

                <li class="nav-item nav-search">
                    <a class="nav-link nav-link-search">
                        <i class="bi bi-search bs-icon-small" @click="toggleSearchBarStatus" ></i>
                    </a>
                    <div class="search-input" :class="showSearchBarStatus" >
                        <div class="search-input-icon"><i class="bi bi-search bs-icon-small" ></i></div>
                        <input class="form-control input" type="text" placeholder="Explore Sabenziy..." tabindex="-1" data-search="search">
                        <div class="search-input-close">
                            <i class="bi bi-x bs-icon-small" @click="toggleSearchBarStatus" ></i>
                        </div>
                        <ul class="search-list search-list-main"></ul>
                    </div>
                </li>
                <!-- End light mode -->
                <li class="nav-item dropdown dropdown-cart me-25">
                    <a class="nav-link" href="#" data-bs-toggle="dropdown">
                        <i class="bi bi-cart bs-icon-small" ></i>
                        <span class="badge rounded-pill bg-primary badge-up cart-item-count">6</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-end">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 me-auto">My Cart</h4>
                                <div class="badge rounded-pill badge-light-primary">4 Items</div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list">
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="../../assets/images/pages/eCommerce/1.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> Apple watch 5</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="1">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$374.90</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="../../assets/images/pages/eCommerce/7.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> Google Home Mini</a></h6><small class="cart-item-by">By Google</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="3">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$129.40</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="../../assets/images/pages/eCommerce/2.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> iPhone 11 Pro</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="2">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$699.00</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="../../assets/images/pages/eCommerce/3.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> iMac Pro</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="1">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$4,999.00</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="../../assets/images/pages/eCommerce/5.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> MacBook Pro</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="1">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$2,999.00</h5>
                                </div>
                            </div>
                        </li>
                        <li class="dropdown-menu-footer">
                            <div class="d-flex justify-content-between mb-1">
                                <h6 class="fw-bolder mb-0">Total:</h6>
                                <h6 class="text-primary fw-bolder mb-0">$10,999.00</h6>
                            </div><a class="btn btn-primary w-100" href="app-ecommerce-checkout.html">Checkout</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown dropdown-notification me-25">
                    <a class="nav-link" href="#" data-bs-toggle="dropdown">
                        <i class="bi bi-bell bs-icon-small" ></i>
                        <span class="badge rounded-pill bg-danger badge-up">5</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-end">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 me-auto">Notifications</h4>
                                <div class="badge rounded-pill badge-light-primary">6 New</div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list"><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar"><img src="../../assets/images/portrait/small/avatar-s-15.jpg" alt="avatar" width="32" height="32"></div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Congratulation Sam 🎉</span>winner!</p><small class="notification-text"> Won the monthly best seller badge.</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar"><img src="../../assets/images/portrait/small/avatar-s-3.jpg" alt="avatar" width="32" height="32"></div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">New message</span>&nbsp;received</p><small class="notification-text"> You have 10 unread messages</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-danger">
                                            <div class="avatar-content">MD</div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Revised Order 👋</span>&nbsp;checkout</p><small class="notification-text"> MD Inc. order updated</small>
                                    </div>
                                </div>
                            </a>
                            <div class="list-item d-flex align-items-center">
                                <h6 class="fw-bolder me-auto mb-0">System Notifications</h6>
                                <div class="form-check form-check-primary form-switch">
                                    <input class="form-check-input" id="systemNotification" type="checkbox" checked="">
                                    <label class="form-check-label" for="systemNotification"></label>
                                </div>
                            </div><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-danger">
                                            <div class="avatar-content"><i class="avatar-icon" data-feather="x"></i></div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Server down</span>&nbsp;registered</p><small class="notification-text"> USA Server is down due to high CPU usage</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-success">
                                            <div class="avatar-content"><i class="avatar-icon" data-feather="check"></i></div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Sales report</span>&nbsp;generated</p><small class="notification-text"> Last month sales report generated</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-warning">
                                            <div class="avatar-content"><i class="avatar-icon" data-feather="alert-triangle"></i></div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">High memory</span>&nbsp;usage</p><small class="notification-text"> BLR Server using high memory</small>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="dropdown-menu-footer">
                            <router-link to="/alert_notifications" class="btn btn-primary w-100">
                                Read all notifications
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="nav-item  me-25">
                    <router-link to="/alert_message" class="nav-link">
                     <i class="bi bi-envelope-open bs-icon-small" ></i>
                     <span class="badge rounded-pill bg-primary badge-up cart-item-count">16</span>
                    </router-link>
                </li>
                <li class="nav-item  me-25">
                    <router-link to="/alert_calendar" class="nav-link">
                        <i class="bi bi-calendar-day bs-icon-small" ></i>
                        <span class="badge rounded-pill bg-primary badge-up cart-item-count">13</span>
                    </router-link>
                </li>
                <li class="nav-item  me-25">
                    <router-link to="/alert_task" class="nav-link">
                        <i class="bi bi-check-square bs-icon-small" ></i>
                        <span class="badge rounded-pill bg-primary badge-up cart-item-count">3</span>
                    </router-link>
                </li>

                <li class="nav-item dropdown dropdown-user">
                    <base-card-drop-down>
                        <template #title>
                            <span class="avatar mx-1">
                                <base-image
                                    :imgName="accountHostProfilePicture"
                                    imgGeo="profile_picture"
                                    imgHost="remote"
                                    imgAlt="Admin Avatar"
                                    imgClass="round"
                                    :imgDim="[40,40]"
                                    v-if=" accountType === 'org_user' "
                                >
                                </base-image>
                            </span>
                            <span class="avatar">
                              <base-image
                                   :imgName="accountProfilePicture"
                                   imgGeo="profile_picture"
                                   imgHost="remote"
                                   imgAlt="Admin Avatar"
                                   imgClass="round"
                                   :imgDim="[40,40]"
                                   :key="profileImageKey"
                               >
                               </base-image>

                               <span class="avatar-status-online"></span>
                            </span>
                        </template>
                        <template #default>
                            <a href="javascript:void(0)" class="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#actionAvatarModal"
                                @click="updateUploadModalKey"
                            >
                                <i class="bi bi-power "  ></i>
                                Edit avatar
                            </a>
                            <router-link to="/personal_account_profile" class="dropdown-item">
                                <i class="bi bi-file-person"  ></i>
                                Profile
                            </router-link>
                            <router-link to="/personal_account_activity_log" class="dropdown-item">
                                <i class="bi bi-list-task " ></i>
                                Activity log

                            </router-link>
                            <router-link to="/personal_account_preferences" class="dropdown-item">
                                <i class="bi bi-ui-checks-grid" ></i>
                                Preferences
                            </router-link>
                            <router-link to="/settings_account_credentials" class="dropdown-item">
                                <i class="bi bi-shield-lock" ></i>
                                Credentials
                            </router-link>
                            <router-link to="/logout" class="dropdown-item">
                                <i class="bi bi-power" ></i>
                                Logout
                            </router-link>
                            <a href="#" class="dropdown-item" >
                                <i class="bi bi-power "  ></i>
                                Logout
                            </a>
                        </template>
                    </base-card-drop-down>
                </li>
            </ul>
        </div>
    </nav>
    <ul class="main-search-list-defaultlist d-none">
        <li class="d-flex align-items-center"><a href="#">
                <h6 class="section-label mt-75 mb-0">Files</h6>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="me-75"><img src="../../assets/images/icons/xls.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Two new item submitted</p><small class="text-muted">Marketing Manager</small>
                    </div>
                </div><small class="search-data-size me-50 text-muted">&apos;17kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="me-75"><img src="../../assets/images/icons/jpg.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">52 JPG file Generated</p><small class="text-muted">FontEnd Developer</small>
                    </div>
                </div><small class="search-data-size me-50 text-muted">&apos;11kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="me-75"><img src="../../assets/images/icons/pdf.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">25 PDF File Uploaded</p><small class="text-muted">Digital Marketing Manager</small>
                    </div>
                </div><small class="search-data-size me-50 text-muted">&apos;150kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="me-75"><img src="../../assets/images/icons/doc.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Anna_Strong.doc</p><small class="text-muted">Web Designer</small>
                    </div>
                </div><small class="search-data-size me-50 text-muted">&apos;256kb</small>
            </a></li>
        <li class="d-flex align-items-center"><a href="#">
                <h6 class="section-label mt-75 mb-0">Members</h6>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar me-75"><img src="../../assets/images/portrait/small/avatar-s-8.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">John Doe</p><small class="text-muted">UI designer</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar me-75"><img src="../../assets/images/portrait/small/avatar-s-1.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Michal Clark</p><small class="text-muted">FontEnd Developer</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar me-75"><img src="../../assets/images/portrait/small/avatar-s-14.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Milena Gibson</p><small class="text-muted">Digital Marketing Manager</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar me-75"><img src="../../assets/images/portrait/small/avatar-s-6.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Anna Strong</p><small class="text-muted">Web Designer</small>
                    </div>
                </div>
            </a></li>
    </ul>
    <ul class="main-search-list-defaultlist-other-list d-none">
        <li class="auto-suggestion justify-content-between"><a class="d-flex align-items-center justify-content-between w-100 py-50">
                <div class="d-flex justify-content-start"><span class="me-75" data-feather="alert-circle"></span><span>No results found.</span></div>
            </a></li>
    </ul>
    <!-- END: Header-->


    <base-modal mId="actionAvatarModal"  mBg="bg-bage"   >
         <template #header-left-slot>
            Edit avatar
         </template>

         <template #default>
             <!-- create admin teams  -->
             <utility-image-upload
                 @emit-update-profile-image-key="updateProfileImageKey"
                 :key="uploadModalKey"
             >
             </utility-image-upload>

         </template>
    </base-modal>
</template>

<script>
import { ref , computed} from 'vue';
import { useStore } from 'vuex';
import UtilityImageUpload from '@/components/utility/UtilityImageUpload.vue';

export default{
    components: {
		UtilityImageUpload,
	},
    setup(){
        const store = useStore();

        const accountType = computed(function(){
            return store.getters['auth/getAuthCredentials']['accountType'];
        });

        const accountHostProfilePicture = computed(function(){
            return store.getters['auth/getAuthCredentials']['accountHostPicture'];
        });

        const accountProfilePicture = computed(function(){
            return store.getters['auth/getAuthCredentials']['accountPicture'];
        });



        const showBookmarkList = ref(false);

        function toggleBookmarkStatus(){
            showBookmarkList.value = !showBookmarkList.value;
        }

        const bookmarkStatus = computed(function(){
            return showBookmarkList.value?'show':'';
        });

        const showSearchBar = ref(false);

        function toggleSearchBarStatus(){
            showBookmarkList.value = false;
            showSearchBar.value = !showSearchBar.value;
        }

        const showSearchBarStatus = computed(function(){
            return showSearchBar.value?'open':'';
        });

        function getRandomKey(){
            const date = new Date();
            return Math.floor(date.getTime() / 1000);
        }

        const profileImageKey = ref("default_key")

        function updateProfileImageKey(){

            profileImageKey.value= getRandomKey()

        }

        const uploadModalKey = ref("default_key")

        function updateUploadModalKey(){

            uploadModalKey.value= getRandomKey()

        }


        return {
            toggleBookmarkStatus,
            bookmarkStatus,
            toggleSearchBarStatus,
            showSearchBarStatus,
            accountProfilePicture,
            uploadModalKey,
            updateUploadModalKey,
            profileImageKey,
            updateProfileImageKey,
            accountType,
            accountHostProfilePicture
        }
    }
}


</script>
