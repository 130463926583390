<template>

    <!-- Log In Modal -->
    <div class="modal fade" :id="mId"  tabindex="-1" role="dialog" :data-bs-backdrop="mBackdrop"
    aria-labelledby="registermodal" aria-hidden="true">
        <div class="modal-dialog " role="document" :class="modalClassOptions">
            <div class="modal-content">
                    <div class="modal-header " :class="mBg" v-if="mHeader">
                        <h4 >
                            <slot name="header-left-slot">

                            </slot>
                        </h4>
                        <slot name="header-right-slot">
                            <button type="button" class="btn btn-flat-secondary close"  data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i class="bi bi-x-lg normal-font"></i>
                                </span>
                            </button>
                        </slot>
                    </div>
                <div class="modal-body p-1" :class="mTopPadding?' py-3':''">
                    <slot name="default"></slot>
                </div>
                <div class="modal-footer" v-if="mFooter">
                    <div class="mf-link">
                        <slot name="footer-left-slot"></slot>
                    </div>
                    <div class="mf-forget">
                        <slot name="footer-right-slot"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</template>

<script>

import { computed } from 'vue';

export default{
    props:{
        mId:{
            type:String,
            required:true
        },
        mSize:{
            type:String,
            default:null
        },
        mCenter:{
            type:Boolean,
            default:true
        },
        mBackdrop:{
            default:"static"
        },
        mHeader:{
            type:Boolean,
            default:true
        },
        mFooter:{
            type:Boolean,
            default:false
        },
        mTopPadding:{
            type:Boolean,
            default:true
        },
        mBg:{
            type:String,
            default:"bg-white"
        }
    },
    setup(props){

        const modalClassOptions = computed(function(){
            let modalSize = props.mSize ? `modal-${props.mSize}` : '';
            let modalCenter = props.mCenter ? 'modal-dialog-centered' : '';

            let finalModalClassOptions = `${modalSize} ${modalCenter}`

            return finalModalClassOptions;
        });

        return {
            modalClassOptions
        }
    }
}
</script>


<style scoped>


.bg-white{
    background: #fff;
}
</style>
