import { createApp } from 'vue';
import axios from 'axios';

// import core pluggins
import router from './router';
import store from './store';
import urls from './site_config';


// import main app file
import App from './App.vue';

// import installed pluggins
import "bootstrap-icons/font/bootstrap-icons.css";

// import custom components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseCardResponse from '@/components/ui/BaseCardResponse.vue';
import BaseCardSplit from '@/components/ui/BaseCardSplit.vue';
import BaseCaptcha from '@/components/ui/BaseCaptcha.vue';
import BaseCardDropDown from '@/components/ui/BaseCardDropDown.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseBadge from '@/components/ui/BaseBadge.vue';
import BaseListTag from '@/components/ui/BaseListTag.vue';
import BaseImage from '@/components/ui/BaseImage.vue';
import BasePagination from '@/components/ui/BasePagination.vue';
import BaseSpinner from '@/components/ui/BaseSpinner.vue';
import BaseModal from '@/components/ui/BaseModal.vue';



// initiate Vue App and assigned to constant app
const app = createApp(App);

// add components to Vue App via app
app.component('base-card-plain',BaseCardPlain);
app.component('base-card-response',BaseCardResponse);
app.component('base-card-split',BaseCardSplit);
app.component('base-card-drop-down',BaseCardDropDown);
app.component('base-button',BaseButton);
app.component('base-badge',BaseBadge);
app.component('base-list-tag',BaseListTag);
app.component('base-image',BaseImage);
app.component('base-pagination',BasePagination);
app.component('base-captcha',BaseCaptcha);
app.component('base-spinner',BaseSpinner);
app.component('base-modal',BaseModal);


// add core pluggins to Vue App via app
app.use(store);
app.use(router);



app.provide('axios', axios)  // provide 'axios'

app.provide('coreUrl', urls.coreUrl)  // provide 'axios'

app.mount('#app');
