export default {
    getAuthToken(state){
        return state.authToken;
    },
    getAuthAuthentication(state){
        return state.authAuthentication;
    },
    getAuthCredentials (state){
        return state.authCredentials ;
    },
    getAuthPermissions (state){
        return state.authPermissions ;
    },
    getLoadAuthState (state){
        return state.loadAuthState ;
    }
};
