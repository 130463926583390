<template>
    <div class="mt-1">
        <canvas class="mb-1 bg-light col-12"   id="captcha_canvas_data" height="60">
        </canvas>
    </div>
</template>

<script>

import {onMounted} from 'vue'

export default{
    props:{
        captchaVal:{
            type: String,
            required: true
        }
    },
    setup(props) {

        onMounted(() => {
            let canvas = document.getElementById("captcha_canvas_data");
            let ctx = canvas.getContext("2d");
            ctx.font = "20px Arial";
            ctx.clearRect(0,0, canvas.width, canvas.height)
            ctx.setTransform (3, -0.3, 0, 1, 0, 0);
            ctx.strokeText(props.captchaVal, 10, 50);
            ctx.setTransform (1, 0, 0, 1, 0, 0);
        })


    }
}
</script>
