<template>

        <div class="alert  alert-dismissible" :class="tagFinalColor" role="alert">
            <div class="alert-body d-flex align-items-center">
                <i class="bi bi-chevron-double-right bs-icon-btn-tiny" v-if="tagIcon"></i>
                <span>
                    <slot></slot>
                </span>
            </div>
        </div>

</template>

<script>
export default{
    props:{
        tagColor:{
            type: String,
            required: true
        },
        tagIcon:{
            type: Boolean,
            required: false
        }
    },
    computed: {
        tagFinalColor(){
            return 'alert-'+this.tagColor;
        }
    }
}
</script>
