export default {
    getApplicantList(state){
        return state.applicantList;
    },
    getRecruitmentPhase(state){
        return state.recruitmentPhase;
    },
    getApplicantFilters(state){
        return state.applicantFilters;
    }
};
