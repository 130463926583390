<template>
    <div :class="navMenuStatus?'':'hideNavMenu' ">
      <nav-menus></nav-menus>
    </div>
  <router-view/>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import NavMenus from '@/components/nav/NavMenus.vue';

export default{
    components: {
        NavMenus
    },
    setup(){
        const store = useStore();

        // get the axios object
        // const axios = inject('axios');  // inject axios

        const navMenuStatus = computed(function(){
            return store.getters['globals/getNavMenu'];
        });

        // when the vue module is created, get the token value, using the try catch


        return {
            navMenuStatus
        }

    }
}
</script>

<style>

.hideNavMenu{
    visibility:hidden
}

.bs-icon-btn{
    font-size: 1.2rem;
}

.bs-icon-tiny{
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
}

.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-normal{
    height: 3.0rem;
    width: 3.0rem;
    font-size: 2.2rem;
}

.bs-icon-btn-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;
}

.tiny-td-col{
    width:  8.33%;
}

.qtr-td-col{
    width:  25%;
}

.cursor-pointer{
    cursor: pointer;
}

.remove-edges{
    border-radius:0px;
}

.tiny-font{
    font-size:11px;
}

.txt-dirty-white{
    color:#f5f5f5;
}

.bg-dirty-white{
    background:#f5f5f5;
}

.twitter-bg{
    background:	#1DA1F2;
}

.twitter-color{
    color:	#1DA1F2;
}

.instagram-bg{
    background:	#e95950;
}

.instagram-color{
    color:	#e95950;
}

.facebook-bg{
    background: #1778F2;
}

.facebook-color{
    color: #1778F2;
}

.linkedin-bg{
    background: #0077b5;
}

.linkedin-color{
    color: #0077b5;
}

.pinterest-bg{
    background: #E60023;
}

.pinterest-color{
    color: #E60023;
}

</style>
