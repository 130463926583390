<template>
    <!-- BEGIN: Main Menu -->
    <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" :class="menuTheme">
        <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item me-auto"><a class="navbar-brand" href="html/ltr/vertical-menu-template-bordered/index.html">
                        <span class="brand-logo">
                            <base-image
                                imgName="logo_icon.png"
                                imgGeo="logo"
                                imgAlt="Sabenzi logo"
                                :imgDim="[24,null]"
                                >
                            </base-image>
                        </span>
                        
                    </a></li>
                <li class="nav-item">
                    <a class="nav-link toggle-dark-theme pe-0" @click="toggleDarkTheme">
                        <i class="bi " :class="themeIcon"  ></i>
                    </a>
                </li>
                <li class="nav-item nav-toggle">
                    <a class="nav-link modern-nav-toggle pe-0 ps-1 mb-2" data-bs-toggle="collapse">
                        <i class="bi bi-x d-block d-xl-none text-primary toggle-icon"  ></i>
                    </a>
                </li>
            </ul>
        </div>
        <!-- <div class="shadow-bottom"></div> -->
        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <!-- Activities start -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Reports</span><i data-feather="more-horizontal"></i>
                </li>
                <li>
                    <router-link to="/dashboard" class="d-flex align-items-center">
                        <i class="bi bi-pie-chart-fill bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="Analytics">Dashboard</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/reports_analytics" class="d-flex align-items-center">
                        <i class="bi bi-kanban bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Analytics</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/reports_activity_log" class="d-flex align-items-center">
                        <i class="bi bi-card-list bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Activity log</span>
                    </router-link>
                </li>
                <!-- Activities end -->

                <!-- Recruitment start -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Recruitment</span><i data-feather="more-horizontal"></i>
                </li>


                <li class="has-sub nav-item"><a class="d-flex align-items-center" href="#"><i class="bi bi-person-bounding-box bs-icon-btn-tiny" ></i><span class="menu-title text-truncate" data-i18n="Invoice">Recruitments</span></a>
                    <ul >
                        <li>
                            <router-link to="/recruitment_post_job_ad" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">Post job Ad</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/recruitment_job_ad_list" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">All job Ads</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link to="/recruitment_talent_pool" class="d-flex align-items-center">
                        <i class="bi bi-folder-check bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Talent pool (TP)</span>
                    </router-link>
                </li>
                <li class="has-sub nav-item"><a class="d-flex align-items-center" href="#"><i class="bi bi-gear-wide-connected bs-icon-btn-tiny" ></i><span class="menu-title text-truncate" data-i18n="Invoice">Settings</span></a>
                    <ul >
                        <li>
                            <router-link to="/recruitment_create_jd" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">Create JD</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/recruitment_jd_list" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">All JDs</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/recruitment_accessment_config" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">Accessment config</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/recruitment_manage_templates" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">Email templates</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/recruitment_third_party" class="d-flex align-items-center">
                                <i class="bi bi-circle bs-icon-btn-tiny" ></i>
                                <span class="menu-item text-truncate" data-i18n="List">3rd Party</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <!-- Recruitment end -->


                <!-- Manage Tests start -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Manage tests</span><i data-feather="more-horizontal"></i>
                </li>

                <li>
                    <router-link to="/test_questions" class="d-flex align-items-center">
                        <i class="bi bi-ui-checks bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Questions</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/test_categories" class="d-flex align-items-center">
                        <i class="bi bi-folder-symlink bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Categories</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/test_tests" class="d-flex align-items-center">
                        <i class="bi bi-puzzle bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Accessments</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/test_list" class="d-flex align-items-center">
                        <i class="bi bi-download bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Load tests</span>
                    </router-link>
                </li>



                <!-- Manage Tests end -->


                <!-- Administration start -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Administration</span><i data-feather="more-horizontal"></i>
                </li>
                <li>
                    <router-link to="/admin_manage_team" class="d-flex align-items-center">
                        <i class="bi bi-people-fill bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Manage team</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/admin_manage_roles_permissions" class="d-flex align-items-center">
                        <i class="bi bi-person-lines-fill bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Manage roles</span>
                    </router-link>
                </li>
                <!-- Administration end -->

                <!-- Organization start -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Account Profile</span><i data-feather="more-horizontal"></i>
                </li>
                <li>
                    <router-link to="/account_view_profile" class="d-flex align-items-center">
                        <i class="bi bi-journal-richtext bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="Analytics">View profile</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/account_update_profile" class="d-flex align-items-center">
                            <i class="bi bi-pencil-square bs-icon-btn-tiny" ></i>
                            <span class="menu-item text-truncate" data-i18n="eCommerce">Update profile</span>
                    </router-link>
                </li>
                <!-- Organization end -->

                <!-- Settings start -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Manage Settings</span><i data-feather="more-horizontal"></i>
                </li>
                <li>
                    <router-link to="/settings_account_preferences" class="d-flex align-items-center">
                        <i class="bi bi-palette2 bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Preferences</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/settings_account_subscription" class="d-flex align-items-center">
                        <i class="bi bi-cash-stack bs-icon-btn-tiny" ></i>
                        <span class="menu-item text-truncate" data-i18n="eCommerce">Subscriptions</span>
                    </router-link>
                </li>
                <!-- Settings end -->

                <!-- Help center start -->
                <li class=" navigation-header"><span data-i18n="Misc">Support center</span><i data-feather="more-horizontal"></i>
                </li>
                <li class=" nav-item">
                    <router-link to="/support_faqs" class="d-flex align-items-center">
                        <i class="bi bi-patch-question bs-icon-btn-tiny" ></i>
                        <span class="menu-title text-truncate" data-i18n="Documentation">FAQs</span>
                    </router-link>
                </li>
                <li class=" nav-item">
                    <router-link to="/support_tutorials" class="d-flex align-items-center">
                        <i class="bi bi-info-circle bs-icon-btn-tiny" ></i>
                        <span class="menu-title text-truncate" data-i18n="Documentation">Tutorials</span>
                    </router-link>
                </li>
                <li class=" nav-item">
                    <router-link to="/support_contact_us" class="d-flex align-items-center">
                        <i class="bi bi-headset bs-icon-btn-tiny" ></i>
                        <span class="menu-title text-truncate" data-i18n="Raise Support">Contact us</span>
                    </router-link>
                </li>
                <li class=" nav-item">
                    <router-link to="/support_features_request" class="d-flex align-items-center">
                        <i class="bi bi-chat-left-dots bs-icon-btn-tiny" ></i>
                        <span class="menu-title text-truncate" data-i18n="Raise Support">Features Request</span>
                    </router-link>
                </li>
                <!-- Help center end -->
            </ul>
        </div>
    </div>
    <!-- END: Main Menu-->
</template>


<script>
import { ref, computed, watch } from 'vue';

export default{
    setup(){

        /**** Start changing page Theme  ****/

        // check the local storage for dark them status, if null, set to true
        const darkThemeStatus = localStorage.getItem("darkThemeStatus") == "true"?ref(true):ref(false);

        // use the computed option to set the sidebar theme depending on darkThemeStatus
        const menuTheme = computed(function(){
            return darkThemeStatus.value ? 'menu-dark' : '';
        });

        // use the computed option to set the page theme icon depending on darkThemeStatus
        const themeIcon = computed(function(){
            return darkThemeStatus.value ? 'bi-sun' : 'bi-moon-stars-fill';
        });

        // use the watch function to set the general theme depending on darkThemeStatus
        watch(darkThemeStatus, (currentValue) => {
            const pageID = document.getElementById("pageIDTag");
            currentValue ? pageID.classList.add('dark-layout') : pageID.classList.remove('dark-layout');
        });


        // the method that actually gets to toggle the page theme.
        function setDarktheme(){
            darkThemeStatus.value = !darkThemeStatus.value;
            localStorage.setItem("darkThemeStatus",darkThemeStatus.value);
        }


        // use this method to toggle the page theme , by calling the setDark theme method
        function toggleDarkTheme(){
            if(localStorage.getItem("darkThemeStatus") === null){
                setDarktheme();
            }else{
                setDarktheme();
            }
        }
        /**** end changing page Theme  ****/

        return {
            menuTheme,
            themeIcon,
            toggleDarkTheme
        }
    }
}


</script>


<style scoped>
.nav .toggle-dark-theme {
    padding: 0;
    margin: 1.571rem 0;
}
</style>
