export default {
    setApplicantList(state, payload){
        state.applicantList = payload;
    },
    setRecruitmentPhase(state, payload){
        state.recruitmentPhase = payload;
    },
    setApplicantFilters(state, payload){
        state.applicantFilters = payload;
    }
};
