<template>
        <div class="app-calendar overflow-hidden border">
            <div class="row g-0">

                <div class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column" id="app-calendar-sidebar">
                    <slot name="sidebar"></slot>
                </div>

                <!-- Content -->
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body " :class="finalPaddingStyle">
                            <slot name="content"></slot>
                        </div>
                    </div>
                </div>
                <!-- /Content -->
                <div class="body-content-overlay"></div>
            </div>
        </div>
</template>

<script>
export default {
    props:{
        padContent:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    computed:{
        finalPaddingStyle(){
            console.log(this.padContent);
            return this.padContent?'p-0':'pb-0';
        }
    }
}
</script>
