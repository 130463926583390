<template>

        <button :type="btnFinalAction" :class="btnFinalStyle" >

                <slot></slot>

        </button>

</template>

<script>
export default{
    props:{
        btnColor:{
            type: String,
            required: true,
            default: "primary"
        },
        btnType:{
            type: String,
            required: false
        },
        btnAction:{
            type: String,
            required: false
        },
        btnSelect:{
            type: Boolean,
            required: false
        }
    },
    computed: {
        btnFinalStyle(){
            const btnFinalColor = 'btn btn-'+this.btnColor;
            const btnFinalTypeColor = 'btn btn-'+this.btnType+'-'+this.btnColor;
            const marginStyle = this.btnSelect?'mt-2 me-1 btnFullWidth':' me-0';
            const btnStyle = this.btnType?`${btnFinalTypeColor} ${marginStyle}`:`${btnFinalColor} ${marginStyle}`;
            return btnStyle;
        },
        btnFinalAction(){
            return this.btnAction == 'button'?'button':'submit';
        }
    }
}
</script>

<style scoped>

.btnFullWidth{
    width:100%;
}

</style>
