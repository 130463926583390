

export default {
    executeSetAuthToken(context, payload){
        context.commit("setAuthToken", payload);
    },
    executeSetAuthAuthentication(context, payload){
        context.commit("setAuthAuthentication", payload);
    },
    executeSetAuthCredentials(context, payload){
        context.commit("setAuthCredentials", payload);
    },
    executeSetAuthPermissions(context, payload){
        context.commit("setAuthPermissions", payload);
    },
    executeSetLoadAuthState(context, payload){

        context.commit("setAuthToken", payload.data.token);
        context.commit("setAuthAuthentication",  payload.data.authentication);
        context.commit("setAuthCredentials",  payload.data.credentials);
        context.commit("setAuthPermissions",  payload.data.permissions);
        context.commit("setLoadAuthState", payload.state)

    }
};
