<template>
    <div>
        <div :class="cardFinalStyle">
            <div class="card-header" v-if="showHeader">
                <slot name="header">
                    <h4 class="card-title"></h4>
                </slot>
            </div>
            <div class="card-body" :class="addBodyPadding">
                <slot></slot>
            </div>
            <div class="card-footer" v-if="showFooter">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: {
        cardBg:{
            type: String,
            required: false,
            default: 'white-bg'
        },
        textAlign:{
            type: String,
            required: false
        },
        showHeader:{
            type: Boolean,
            required: false,
            default: true
        },
        showFooter:{
            type: Boolean,
            required: false
        },
        addPadding:{
            type: String,
            required: false,
            default: ''
        },
        addBodyPadding:{
            type: String,
            required: false,
            default: ''
        }
    },
    computed:{
        cardFinalStyle(){
            return `card ${this.textAlign} ${this.addPadding} ${this.cardBg}`;
        }
    }
}
</script>

<style scoped>
.bage-bg{
    background-color: #f5f5f5;
}

.white-bg{
    background-color: #fff;
}
</style>
