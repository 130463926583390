<template>
      <input type="file" accept="image/*" @change="onFileChange" id="fileForm" hidden >
        <cropper class="cropper"
               ref="cropper"
               :src="currentImg"
               :canvas="{ height: 256,width: 256}"
               :stencil-props="{
                   handlers: {},
                   movable: true,
                   scalable: false
                   }"
               :stencil-size="{width:256,height:256}"
               @change="changeAvatar"
               v-if="!succesfulUpload"
           >
             Click here or the <i class="bi bi-upload"></i> icon to upload a picture.
          </cropper>
          <div v-if="jpegImgUrl && !succesfulUpload" class="py-1">
               <img :src="jpegImgUrl" class=" rounded" />
         </div>

         <div v-if="succesfulUpload" class="pb-2 ">
              <img :src="jpegImgUrl" class="rounded successful-upload" />
        </div>

        <div class="col-12" v-if="fv.reqFeedback.status">

            <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
        </div>


       <div class="action-area mt-3 text-center" v-if="!succesfulUpload">
           <label class="bi bi-upload me-1 cursor-pointer" for="fileForm"></label>
           <i class="bi bi-arrow-clockwise mx-1 cursor-pointer"  @click="rotateImg(90)" v-if="processButtons"></i>
           <i class="bi bi-arrow-counterclockwise mx-1 cursor-pointer"  @click="rotateImg(-90)" v-if="processButtons"></i>
           <i class="bi bi-trash-fill mx-1 cursor-pointer"  @click="restartUpload" v-if="processButtons"></i>
           <i class="bi bi-folder-check ms-1 cursor-pointer"  @click="confirmChangeAvatar" v-if="processButtons"></i>
       </div>

</template>

<script>
import { ref,reactive,inject } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default{
    components: {
		Cropper
	},
    emits:['emitUpdateProfileImageKey'],
    setup( _ , { emit }){

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        const currentImg = ref("")
        const jpegImgUrl = ref(null)
        const succesfulUpload = ref(false)
        const processButtons = ref(false)

        const imgCoordinates = ref({
				width: 0,
				height: 0,
				left: 0,
				top: 0,
			})

        function changeAvatar({ coordinates, canvas }){
            imgCoordinates.value = coordinates
            jpegImgUrl.value = canvas.toDataURL("image/jpeg");
		}

        function onFileChange(e) {

          const file = e.target.files[0];

          if(file){
              let url = URL.createObjectURL(file);
              currentImg.value = url;
              processButtons.value = true;
          }

        }

        const cropper = ref(null)

        function rotateImg(angle) {
			cropper.value.rotate(angle);
		}

        function restartUpload(){
            currentImg.value = null
            jpegImgUrl.value = null
            processButtons.value = false;
        }

        // create variables for the values to be submitted
       const fv = reactive({
           image : {status:false, val:"", msg:false, active:true, validate:["required"]},
           reqFeedback : {status:false, val:"", msg:false},
           showSubmit : {status:false, val:true}
       })


       async function confirmChangeAvatar(){


           fv.showSubmit.val = false;

           // load the form data , and append all the necessary fields
           let formData = new FormData();

           formData.append("fv_image", jpegImgUrl.value);

           // post the data using axios
           try{

               const response = await axios.put(
                   coreUrl.backendUrl+'create_avatar_change',
                   formData,
                   {
                       withCredentials: true,
                       headers:{
                          "Content-Type": "multipart/form-data"
                       }
                   }
               );

               // If successful, get the resppnse data
               const responseData = response.data;

               if(responseData.status){

                   succesfulUpload.value = true
                   // update is account created status
                   fv.reqFeedback.status = true;
                   fv.reqFeedback.val = "success";
                   fv.reqFeedback.msg = "image was successfully updated";

                   fv.showSubmit.val = true;

                   emit('emitUpdateProfileImageKey')
               }else{
                   succesfulUpload.value = false
                   fv.reqFeedback.status = true;
                   fv.reqFeedback.msg = "image upload failed";
                   fv.reqFeedback.val = "danger";
                   fv.showSubmit.status = false;
                   fv.showSubmit.val = true;
               }




           }catch(error){


               // load the error to the appropriate fiel

               succesfulUpload.value = false
               fv.reqFeedback.status = true;
               fv.reqFeedback.msg = "image upload failed error";
               fv.reqFeedback.val = "danger";
               fv.showSubmit.status = false;
               fv.showSubmit.val = true;

               // end load the error to the appropriate field

           }
       }


        return {
            currentImg,
            jpegImgUrl,
            changeAvatar,
            confirmChangeAvatar,
            succesfulUpload,
            processButtons,
            restartUpload,
            onFileChange,
            cropper,
            rotateImg,
            fv
        }
    }
}


</script>


<style scoped>

.cropper {
	width: 100%;
    min-height: 20rem;
	background: #DDD;
}

.action-area{
    font-size:2rem;
}

.successful-upload{
    width:100%
}


</style>
